import { EditMaterialContentLessonViewProps } from "src/modules/courses/models";
import { ShowMaterialLesson } from "../../show-material/lesson";
import { splitProps } from "solid-js";
import { SidePanel } from "./side-panel";
import { materialContentLessonTemplateDetails } from "../../../mocks";

export function EditMaterialLesson(props: EditMaterialContentLessonViewProps) {
  const [local] = splitProps(props, ["$", "content", "onUpdate"]);
  const Editor = SidePanel({
    ...local.content,
    style: local.content?.style ?? materialContentLessonTemplateDetails.data.pages[0].template.style,
  });
  local.$.actions.setLayout({
    title: "Edit Material Lesson",
    sideInfo: [
      {
        element: () => <Editor.View $={local.$} onUpdate={local.onUpdate} />,
      },
    ],
  });
  // function onUpdate() {
  //   if (edit_draft) {

  //   }
  // }
  // Editor.$material_lesson.on((events, { value }) => {
  //   edit_draft = value
  //   console.log("updating material lesson :: ", value);
  // });
  return <ShowMaterialLesson $={props.$} content={Editor.$material_lesson.value as any} edit />;
}
